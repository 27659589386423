.footer-container {
  background-color: lightgrey;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  display: none;
}

.footer-logo-2 {
  display: inline;
  width: 50%;
}

.footer-column {
  width: 80%;
}

.footer-font {
  font-family: "sans-serif";
}

.footer-fat {
  font-weight: bold;
  font-size: 18px;
}

.footer-text {
  font-size: 18px;
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-link:hover {
  cursor: pointer;
}

.footer-social-logo {
  margin-right: 5px;
}

.footer-social-container {
  align-items: center;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .footer-container {
    background-color: lightgrey;
    height: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .footer-column {
    width: 33%;
  }

  .footer-logo {
    display: inline;
    width: 60%;
  }

  .footer-logo-2 {
    display: none;
  }
}
