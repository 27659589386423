/* All versions */
.navbar {
  height: 100px;
  background-color: white;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 998;
}

.navbar-logo {
  height: 90px;
  margin: auto;
  margin-left: 10px;
}

.navbar-logo:hover {
  cursor: pointer;
}

.navbar-elements {
  display: flex;
  align-items: center;
}

.navbar-element {
  display: none;
  text-decoration: none;
  color: black;
}

/* Mobile version */
.navbar-menu {
  display: flex;
  margin-right: 20px;
  border: solid black 1px;
  border-radius: 5px;
  padding: 2px;
}

.navbar-menu:hover {
  cursor: pointer;
}

.mobile-navbar-menu {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 100px;
  z-index: 999;
}

.mobile-navbar-menu-item {
  width: 100%;
  padding: 5px;
  border-bottom: solid black 1px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.mobile-navbar-menu-item:hover {
  cursor: pointer;
  color: rgb(24, 110, 171);
}

.mobile-dropdown {
  width: 100%;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .navbar-element {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    margin: 30px;
  }

  .navbar-element:hover {
    cursor: pointer;
    color: rgb(24, 110, 171);
    border-bottom: solid rgb(24, 110, 171) 3px;
  }

  .navbar-menu {
    display: none;
  }

  .mobile-navbar-menu-item {
    display: none;
  }

  .dropdown-products {
    border-top: solid rgb(24, 110, 171) 3px;
    font-weight: normal;
    background-color: white;
  }

  .dropdown-product {
    text-decoration: none;
    color: black;
  }

  .dropdown-product:hover {
    font-weight: bold;
    color: rgb(24, 110, 171);
  }

  .dropdown-trigger {
    font-size: 20px;
    font-weight: bold;
    margin: 30px;
  }

  .dropdown-trigger:hover {
    color: rgb(24, 110, 171);
    position: relative;
    top: 25px;
    cursor: default;
  }

  .product-arrow {
    display: flex;
  }
}
