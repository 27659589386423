/* All versions */
.homepage {
  padding-top: 100px;
}

.line-separator-home {
  margin: 30px auto;
  width: 70%;
  height: 0;
  border-bottom: solid black 1.5px;
}

.image-markis-homepage {
  width: 100%;
}

.homepage-text-container {
  justify-content: center;
  padding-bottom: 30px;
  margin: 30px auto;
  border-bottom: solid black 2px;
}

.homepage-text {
  font-family: "sans-serif";
  font-size: 20px;
}

.homepage-kontakt-btn {
  font-family: "sans-serif";
  font-size: 24px;
  background-color: rgb(129, 179, 245);
  border-radius: 5px;
}

.homepage-kontakt-btn:hover {
  cursor: pointer;
  background-color: rgb(74, 145, 237);
}

.homepage-products-container:hover {
  cursor: pointer;
}

.text-markis-homepage {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 3px;
  font-family: "sans-serif";
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.text-markis-homepage-inne {
  font-size: 32px;
}

.text-markis-homepage-ute {
  font-size: 32px;
}

/* Mobile version */
.homepage-text-container {
  width: 85%;
}

.homepage-products-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.text-markis-homepage-ute {
  left: 50%;
  top: 25%;
}

.text-markis-homepage-inne {
  left: 50%;
  top: 75%;
}

.image-markis-homepage-inne {
  width: 50%;
}

.image-markis-homepage-ute {
  width: 90%;
  border-bottom: solid black 2px;
}

.image-markis-homepage-inne {
  width: 90%;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .homepage-text-container {
    width: 50%;
  }

  .homepage-products-container {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .text-markis-homepage-ute {
    left: 25%;
    top: 50%;
  }

  .text-markis-homepage-inne {
    left: 75%;
    top: 50%;
  }

  .image-markis-homepage-inne {
    width: 50%;
    margin-left: -2px;
  }

  .image-markis-homepage-ute {
    width: 50%;
    border-right: solid black 2px;
    z-index: 1;
    border-bottom: none;
  }
}
