.contact-page-container {
  padding-top: 100px;
}

.contact-box {
  margin: 30px auto;
  width: 90%;
  height: 100%;
}

.contact-header {
  font-size: 42px;
}

.contact-text {
  font-size: 18px;
}

.social-logo {
  margin-right: 5px;
}

.contact-information-item {
  margin: 20px;
}

.employee-image {
  margin-top: 20px;
  width: 70%;
}

.contact-name {
  margin: 10px 0 50px 0;
  font-size: 24px;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .employee-image {
    width: 25%;
  }

  .contact-header {
    font-size: 50px;
  }

  .contact-text {
    font-size: 24px;
  }

  .contact-box {
    margin: 30px auto;
    width: 80%;
  }
}
