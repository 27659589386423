/* All versions */
.product-page-container {
  padding-top: 100px;
}

.line-separator {
  margin: 0 auto;
  width: 70%;
  height: 0;
  border-bottom: solid black 1.5px;
}

/* Mobile version */
.product-page-header {
  font-size: 42px;
}

.product-items-container {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-image-container {
  width: 100%;
}

.product-image {
  width: 90%;
}

.product-text-field {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-text-header {
  font-size: 36px;
  color: rgb(91, 156, 231);
  font-family: "sans-serif";
}

.product-text {
  font-size: 22px;
  width: 80%;
  font-family: "sans-serif";
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .product-page-header {
    font-size: 50px;
  }

  .product-items-container {
    margin: 25px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .product-image-container {
    width: 50%;
  }

  .product-image {
    width: 80%;
  }

  .product-text-field {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .product-text-header {
    width: 80%;
    margin-left: 10%;
    text-align: left;
    font-size: 40px;
    color: rgb(91, 156, 231);
    font-family: "sans-serif";
  }

  .product-text {
    text-align: left;
    margin-left: 10%;
    font-size: 24px;
    width: 80%;
    font-family: "sans-serif";
  }
} ;
